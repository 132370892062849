/*==================================TREE====================================*/

li {
  text-decoration: none;
}

.tree,
.tree ul {
  margin: 0 0 0 0.5em;
  /* indentation */
  padding: 2px;
  list-style: none;
  color: #444;
  position: relative;
}

.tree .fa {
  color: #139873;
}

.tree input,
select {
  padding: 0px;
  font-size: 10px;
  height: 25px;
  /* font-family: sans-serif,Roboto; */
}

.tree ul {
  margin-left: .2em
}

/* (indentation/2) */

.tree:before,
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
   border-color: #139873;
}

.tree li {
  margin: 0;
  padding: 0 1.5em;
  /* indentation + .5em */
  line-height: 2em;
  /* default list item's `line-height` */
  font-weight: 300;
  position: relative;
  font-size: 16px;
}

.tree li:before {
  content: "";
  display: block;
  width: 20px;
  /* same with indentation */
  height: 0;
  border-top: 1px solid;
  border-color: #139873;
  margin-top: 1px;
  /* border top width */
  position: absolute;
  top: 1em;
  /* (line-height/2) */
  left: 0;
}

.tree li:last-child:before {
  background: transparent;
  /* same with body background */
  height: auto;
  top: 1em;
  /* (line-height/2) */
  bottom: 0;
}

.bom .tree .btn {
  font-size: 8px;
  padding: 4px;
  margin-top: 9px;
}

.bom table td {
  font-style: italic;
  font-size: 16px;
}

.dropdown .btn .fa {
  color: black;
}

[name~=nav] .btn .fa {
  color: black;
}

.bom .fa {
  color: #1E5138;
}

.btn .fa {
  color: #fff;
}

.cover {
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  box-shadow: inset -1px -23px 81px 0px rgba(0, 0, 0, 1);
}

.cover:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 0, 0, 0), #f2f2f2 90%);
}

.cover h1 {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #404040;
  font-style: italic;
  font-size: 50px;
  font-size-adjust: auto;
  text-shadow: 0 1px #FFF;
}

.cover img {
  width: 100%;
}

.panel-default .image-cover {
  width: 100%;
}
