/* You can add global styles to this file, and also import other style files */

@import url('./assets/ui-switch/ui-switch.component.css');
@import url('./assets/estilos/bom.css');
@import url('./assets/estilos/calendar.css');
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import 'flexboxgrid.min.css'; */

html,
body {
  height: 100%;
}

.modal-content {
  top: 15px;
  bottom: 15px;
}

.modal {
  position: absolute !important;
}

@media (max-width: 599px) {
  .modal-content {
    top: 40px !important;
  }
}

@media (max-width: 599px) {
  .modal-content {
    top: 40px !important;
  }
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.color-principal {
  background: #0c8361 !important;
}

.color-principal-text {
  color: #0c8361 !important;
}

.color-proveedor-text {
  color: #009FE3 !important;
}

.red {
  background-color: #f44336 !important;
}

.red-text {
  color: #f44336 !important;
}

.red.lighten-5 {
  background-color: #ffebee !important;
}

.red-text.text-lighten-5 {
  color: #ffebee !important;
}

.red.lighten-4 {
  background-color: #ffcdd2 !important;
}

.red-text.text-lighten-4 {
  color: #ffcdd2 !important;
}

.red.lighten-3 {
  background-color: #ef9a9a !important;
}

.red-text.text-lighten-3 {
  color: #ef9a9a !important;
}

.red.lighten-2 {
  background-color: #e57373 !important;
}

.red-text.text-lighten-2 {
  color: #e57373 !important;
}

.red.lighten-1 {
  background-color: #ef5350 !important;
}

.red-text.text-lighten-1 {
  color: #ef5350 !important;
}

.red.darken-1 {
  background-color: #e53935 !important;
}

.red-text.text-darken-1 {
  color: #e53935 !important;
}

.red.darken-2 {
  background-color: #d32f2f !important;
}

.red-text.text-darken-2 {
  color: #d32f2f !important;
}

.red.darken-3 {
  background-color: #c62828 !important;
}

.red-text.text-darken-3 {
  color: #c62828 !important;
}

.red.darken-4 {
  background-color: #b71c1c !important;
}

.red-text.text-darken-4 {
  color: #b71c1c !important;
}

.red.accent-1 {
  background-color: #ff8a80 !important;
}

.red-text.text-accent-1 {
  color: #ff8a80 !important;
}

.red.accent-2 {
  background-color: #ff5252 !important;
}

.red-text.text-accent-2 {
  color: #ff5252 !important;
}

.red.accent-3 {
  background-color: #ff1744 !important;
}

.red-text.text-accent-3 {
  color: #ff1744 !important;
}

.red.accent-4 {
  background-color: #d50000 !important;
}

.red-text.text-accent-4 {
  color: #d50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.pink.lighten-5 {
  background-color: #fce4ec !important;
}

.pink-text.text-lighten-5 {
  color: #fce4ec !important;
}

.pink.lighten-4 {
  background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important;
}

.pink.lighten-3 {
  background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3 {
  color: #f48fb1 !important;
}

.pink.lighten-2 {
  background-color: #f06292 !important;
}

.pink-text.text-lighten-2 {
  color: #f06292 !important;
}

.pink.lighten-1 {
  background-color: #ec407a !important;
}

.pink-text.text-lighten-1 {
  color: #ec407a !important;
}

.pink.darken-1 {
  background-color: #d81b60 !important;
}

.pink-text.text-darken-1 {
  color: #d81b60 !important;
}

.pink.darken-2 {
  background-color: #c2185b !important;
}

.pink-text.text-darken-2 {
  color: #c2185b !important;
}

.pink.darken-3 {
  background-color: #ad1457 !important;
}

.pink-text.text-darken-3 {
  color: #ad1457 !important;
}

.pink.darken-4 {
  background-color: #880e4f !important;
}

.pink-text.text-darken-4 {
  color: #880e4f !important;
}

.pink.accent-1 {
  background-color: #ff80ab !important;
}

.pink-text.text-accent-1 {
  color: #ff80ab !important;
}

.pink.accent-2 {
  background-color: #ff4081 !important;
}

.pink-text.text-accent-2 {
  color: #ff4081 !important;
}

.pink.accent-3 {
  background-color: #f50057 !important;
}

.pink-text.text-accent-3 {
  color: #f50057 !important;
}

.pink.accent-4 {
  background-color: #c51162 !important;
}

.pink-text.text-accent-4 {
  color: #c51162 !important;
}

.purple {
  background-color: #9c27b0 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.purple.lighten-5 {
  background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important;
}

.purple.lighten-4 {
  background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4 {
  color: #e1bee7 !important;
}

.purple.lighten-3 {
  background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3 {
  color: #ce93d8 !important;
}

.purple.lighten-2 {
  background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2 {
  color: #ba68c8 !important;
}

.purple.lighten-1 {
  background-color: #ab47bc !important;
}

.purple-text.text-lighten-1 {
  color: #ab47bc !important;
}

.purple.darken-1 {
  background-color: #8e24aa !important;
}

.purple-text.text-darken-1 {
  color: #8e24aa !important;
}

.purple.darken-2 {
  background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2 {
  color: #7b1fa2 !important;
}

.purple.darken-3 {
  background-color: #6a1b9a !important;
}

.purple-text.text-darken-3 {
  color: #6a1b9a !important;
}

.purple.darken-4 {
  background-color: #4a148c !important;
}

.purple-text.text-darken-4 {
  color: #4a148c !important;
}

.purple.accent-1 {
  background-color: #ea80fc !important;
}

.purple-text.text-accent-1 {
  color: #ea80fc !important;
}

.purple.accent-2 {
  background-color: #e040fb !important;
}

.purple-text.text-accent-2 {
  color: #e040fb !important;
}

.purple.accent-3 {
  background-color: #d500f9 !important;
}

.purple-text.text-accent-3 {
  color: #d500f9 !important;
}

.purple.accent-4 {
  background-color: #aa00ff !important;
}

.purple-text.text-accent-4 {
  color: #aa00ff !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important;
}

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important;
}

.deep-purple.lighten-3 {
  background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important;
}

.deep-purple.lighten-2 {
  background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important;
}

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important;
}

.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important;
}

.deep-purple.darken-2 {
  background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2 {
  color: #512da8 !important;
}

.deep-purple.darken-3 {
  background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important;
}

.deep-purple.darken-4 {
  background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4 {
  color: #311b92 !important;
}

.deep-purple.accent-1 {
  background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1 {
  color: #b388ff !important;
}

.deep-purple.accent-2 {
  background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important;
}

.deep-purple.accent-3 {
  background-color: #651fff !important;
}

.deep-purple-text.text-accent-3 {
  color: #651fff !important;
}

.deep-purple.accent-4 {
  background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.indigo.lighten-5 {
  background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important;
}

.indigo.lighten-4 {
  background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important;
}

.indigo.lighten-3 {
  background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3 {
  color: #9fa8da !important;
}

.indigo.lighten-2 {
  background-color: #7986cb !important;
}

.indigo-text.text-lighten-2 {
  color: #7986cb !important;
}

.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important;
}

.indigo.darken-1 {
  background-color: #3949ab !important;
}

.indigo-text.text-darken-1 {
  color: #3949ab !important;
}

.indigo.darken-2 {
  background-color: #303f9f !important;
}

.indigo-text.text-darken-2 {
  color: #303f9f !important;
}

.indigo.darken-3 {
  background-color: #283593 !important;
}

.indigo-text.text-darken-3 {
  color: #283593 !important;
}

.indigo.darken-4 {
  background-color: #1a237e !important;
}

.indigo-text.text-darken-4 {
  color: #1a237e !important;
}

.indigo.accent-1 {
  background-color: #8c9eff !important;
}

.indigo-text.text-accent-1 {
  color: #8c9eff !important;
}

.indigo.accent-2 {
  background-color: #536dfe !important;
}

.indigo-text.text-accent-2 {
  color: #536dfe !important;
}

.indigo.accent-3 {
  background-color: #3d5afe !important;
}

.indigo-text.text-accent-3 {
  color: #3d5afe !important;
}

.indigo.accent-4 {
  background-color: #304ffe !important;
}

.indigo-text.text-accent-4 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196f3 !important;
}

.blue-text {
  color: #2196f3 !important;
}

.blue.lighten-5 {
  background-color: #e3f2fd !important;
}

.blue-text.text-lighten-5 {
  color: #e3f2fd !important;
}

.blue.lighten-4 {
  background-color: #bbdefb !important;
}

.blue-text.text-lighten-4 {
  color: #bbdefb !important;
}

.blue.lighten-3 {
  background-color: #90caf9 !important;
}

.blue-text.text-lighten-3 {
  color: #90caf9 !important;
}

.blue.lighten-2 {
  background-color: #64b5f6 !important;
}

.blue-text.text-lighten-2 {
  color: #64b5f6 !important;
}

.blue.lighten-1 {
  background-color: #42a5f5 !important;
}

.blue-text.text-lighten-1 {
  color: #42a5f5 !important;
}

.blue.darken-1 {
  background-color: #1e88e5 !important;
}

.blue-text.text-darken-1 {
  color: #1e88e5 !important;
}

.blue.darken-2 {
  background-color: #1976d2 !important;
}

.blue-text.text-darken-2 {
  color: #1976d2 !important;
}

.blue.darken-3 {
  background-color: #1565c0 !important;
}

.blue-text.text-darken-3 {
  color: #1565c0 !important;
}

.blue.darken-4 {
  background-color: #0d47a1 !important;
}

.blue-text.text-darken-4 {
  color: #0d47a1 !important;
}

.blue.accent-1 {
  background-color: #82b1ff !important;
}

.blue-text.text-accent-1 {
  color: #82b1ff !important;
}

.blue.accent-2 {
  background-color: #448aff !important;
}

.blue-text.text-accent-2 {
  color: #448aff !important;
}

.blue.accent-3 {
  background-color: #2979ff !important;
}

.blue-text.text-accent-3 {
  color: #2979ff !important;
}

.blue.accent-4 {
  background-color: #2962ff !important;
}

.blue-text.text-accent-4 {
  color: #2962ff !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}

.light-blue-text {
  color: #03a9f4 !important;
}

.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important;
}

.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important;
}

.light-blue.lighten-3 {
  background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important;
}

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important;
}

.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important;
}

.light-blue.darken-1 {
  background-color: #039be5 !important;
}

.light-blue-text.text-darken-1 {
  color: #039be5 !important;
}

.light-blue.darken-2 {
  background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2 {
  color: #0288d1 !important;
}

.light-blue.darken-3 {
  background-color: #0277bd !important;
}

.light-blue-text.text-darken-3 {
  color: #0277bd !important;
}

.light-blue.darken-4 {
  background-color: #01579b !important;
}

.light-blue-text.text-darken-4 {
  color: #01579b !important;
}

.light-blue.accent-1 {
  background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1 {
  color: #80d8ff !important;
}

.light-blue.accent-2 {
  background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2 {
  color: #40c4ff !important;
}

.light-blue.accent-3 {
  background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3 {
  color: #00b0ff !important;
}

.light-blue.accent-4 {
  background-color: #0091ea !important;
}

.light-blue-text.text-accent-4 {
  color: #0091ea !important;
}

.cyan {
  background-color: #00bcd4 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.cyan.lighten-5 {
  background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important;
}

.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important;
}

.cyan.lighten-3 {
  background-color: #80deea !important;
}

.cyan-text.text-lighten-3 {
  color: #80deea !important;
}

.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important;
}

.cyan.lighten-1 {
  background-color: #26c6da !important;
}

.cyan-text.text-lighten-1 {
  color: #26c6da !important;
}

.cyan.darken-1 {
  background-color: #00acc1 !important;
}

.cyan-text.text-darken-1 {
  color: #00acc1 !important;
}

.cyan.darken-2 {
  background-color: #0097a7 !important;
}

.cyan-text.text-darken-2 {
  color: #0097a7 !important;
}

.cyan.darken-3 {
  background-color: #00838f !important;
}

.cyan-text.text-darken-3 {
  color: #00838f !important;
}

.cyan.darken-4 {
  background-color: #006064 !important;
}

.cyan-text.text-darken-4 {
  color: #006064 !important;
}

.cyan.accent-1 {
  background-color: #84ffff !important;
}

.cyan-text.text-accent-1 {
  color: #84ffff !important;
}

.cyan.accent-2 {
  background-color: #18ffff !important;
}

.cyan-text.text-accent-2 {
  color: #18ffff !important;
}

.cyan.accent-3 {
  background-color: #00e5ff !important;
}

.cyan-text.text-accent-3 {
  color: #00e5ff !important;
}

.cyan.accent-4 {
  background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}

.teal-text {
  color: #009688 !important;
}

.teal.lighten-5 {
  background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important;
}

.teal.lighten-4 {
  background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4 {
  color: #b2dfdb !important;
}

.teal.lighten-3 {
  background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3 {
  color: #80cbc4 !important;
}

.teal.lighten-2 {
  background-color: #4db6ac !important;
}

.teal-text.text-lighten-2 {
  color: #4db6ac !important;
}

.teal.lighten-1 {
  background-color: #26a69a !important;
}

.teal-text.text-lighten-1 {
  color: #26a69a !important;
}

.teal.darken-1 {
  background-color: #00897b !important;
}

.teal-text.text-darken-1 {
  color: #00897b !important;
}

.teal.darken-2 {
  background-color: #00796b !important;
}

.teal-text.text-darken-2 {
  color: #00796b !important;
}

.teal.darken-3 {
  background-color: #00695c !important;
}

.teal-text.text-darken-3 {
  color: #00695c !important;
}

.teal.darken-4 {
  background-color: #004d40 !important;
}

.teal-text.text-darken-4 {
  color: #004d40 !important;
}

.teal.accent-1 {
  background-color: #a7ffeb !important;
}

.teal-text.text-accent-1 {
  color: #a7ffeb !important;
}

.teal.accent-2 {
  background-color: #64ffda !important;
}

.teal-text.text-accent-2 {
  color: #64ffda !important;
}

.teal.accent-3 {
  background-color: #1de9b6 !important;
}

.teal-text.text-accent-3 {
  color: #1de9b6 !important;
}

.teal.accent-4 {
  background-color: #00bfa5 !important;
}

.teal-text.text-accent-4 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4caf50 !important;
}

.green-text {
  color: #4caf50 !important;
}

.green.lighten-5 {
  background-color: #e8f5e9 !important;
}

.green-text.text-lighten-5 {
  color: #e8f5e9 !important;
}

.green.lighten-4 {
  background-color: #c8e6c9 !important;
}

.green-text.text-lighten-4 {
  color: #c8e6c9 !important;
}

.green.lighten-3 {
  background-color: #a5d6a7 !important;
}

.green-text.text-lighten-3 {
  color: #a5d6a7 !important;
}

.green.lighten-2 {
  background-color: #81c784 !important;
}

.green-text.text-lighten-2 {
  color: #81c784 !important;
}

.green.lighten-1 {
  background-color: #66bb6a !important;
}

.green-text.text-lighten-1 {
  color: #66bb6a !important;
}

.green.darken-1 {
  background-color: #43a047 !important;
}

.green-text.text-darken-1 {
  color: #43a047 !important;
}

.green.darken-2 {
  background-color: #388e3c !important;
}

.green-text.text-darken-2 {
  color: #388e3c !important;
}

.green.darken-3 {
  background-color: #2e7d32 !important;
}

.green-text.text-darken-3 {
  color: #2e7d32 !important;
}

.green.darken-4 {
  background-color: #1b5e20 !important;
}

.green-text.text-darken-4 {
  color: #1b5e20 !important;
}

.green.accent-1 {
  background-color: #b9f6ca !important;
}

.green-text.text-accent-1 {
  color: #b9f6ca !important;
}

.green.accent-2 {
  background-color: #69f0ae !important;
}

.green-text.text-accent-2 {
  color: #69f0ae !important;
}

.green.accent-3 {
  background-color: #00e676 !important;
}

.green-text.text-accent-3 {
  color: #00e676 !important;
}

.green.accent-4 {
  background-color: #00c853 !important;
}

.green-text.text-accent-4 {
  color: #00c853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.light-green.lighten-5 {
  background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important;
}

.light-green.lighten-4 {
  background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important;
}

.light-green.lighten-3 {
  background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important;
}

.light-green.lighten-2 {
  background-color: #aed581 !important;
}

.light-green-text.text-lighten-2 {
  color: #aed581 !important;
}

.light-green.lighten-1 {
  background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important;
}

.light-green.darken-1 {
  background-color: #7cb342 !important;
}

.light-green-text.text-darken-1 {
  color: #7cb342 !important;
}

.light-green.darken-2 {
  background-color: #689f38 !important;
}

.light-green-text.text-darken-2 {
  color: #689f38 !important;
}

.light-green.darken-3 {
  background-color: #558b2f !important;
}

.light-green-text.text-darken-3 {
  color: #558b2f !important;
}

.light-green.darken-4 {
  background-color: #33691e !important;
}

.light-green-text.text-darken-4 {
  color: #33691e !important;
}

.light-green.accent-1 {
  background-color: #ccff90 !important;
}

.light-green-text.text-accent-1 {
  color: #ccff90 !important;
}

.light-green.accent-2 {
  background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2 {
  color: #b2ff59 !important;
}

.light-green.accent-3 {
  background-color: #76ff03 !important;
}

.light-green-text.text-accent-3 {
  color: #76ff03 !important;
}

.light-green.accent-4 {
  background-color: #64dd17 !important;
}

.light-green-text.text-accent-4 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}

.lime-text {
  color: #cddc39 !important;
}

.lime.lighten-5 {
  background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important;
}

.lime.lighten-4 {
  background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important;
}

.lime.lighten-3 {
  background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3 {
  color: #e6ee9c !important;
}

.lime.lighten-2 {
  background-color: #dce775 !important;
}

.lime-text.text-lighten-2 {
  color: #dce775 !important;
}

.lime.lighten-1 {
  background-color: #d4e157 !important;
}

.lime-text.text-lighten-1 {
  color: #d4e157 !important;
}

.lime.darken-1 {
  background-color: #c0ca33 !important;
}

.lime-text.text-darken-1 {
  color: #c0ca33 !important;
}

.lime.darken-2 {
  background-color: #afb42b !important;
}

.lime-text.text-darken-2 {
  color: #afb42b !important;
}

.lime.darken-3 {
  background-color: #9e9d24 !important;
}

.lime-text.text-darken-3 {
  color: #9e9d24 !important;
}

.lime.darken-4 {
  background-color: #827717 !important;
}

.lime-text.text-darken-4 {
  color: #827717 !important;
}

.lime.accent-1 {
  background-color: #f4ff81 !important;
}

.lime-text.text-accent-1 {
  color: #f4ff81 !important;
}

.lime.accent-2 {
  background-color: #eeff41 !important;
}

.lime-text.text-accent-2 {
  color: #eeff41 !important;
}

.lime.accent-3 {
  background-color: #c6ff00 !important;
}

.lime-text.text-accent-3 {
  color: #c6ff00 !important;
}

.lime.accent-4 {
  background-color: #aeea00 !important;
}

.lime-text.text-accent-4 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}

.yellow-text {
  color: #ffde03 !important;
}

.yellow.lighten-5 {
  background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5 {
  color: #fffde7 !important;
}

.yellow.lighten-4 {
  background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important;
}

.yellow.lighten-3 {
  background-color: #fff59d !important;
}

.yellow-text.text-lighten-3 {
  color: #fff59d !important;
}

.yellow.lighten-2 {
  background-color: #fff176 !important;
}

.yellow-text.text-lighten-2 {
  color: #fff176 !important;
}

.yellow.lighten-1 {
  background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1 {
  color: #ffee58 !important;
}

.yellow.darken-1 {
  background-color: #fdd835 !important;
}

.yellow-text.text-darken-1 {
  color: #fdd835 !important;
}

.yellow.darken-2 {
  background-color: #fbc02d !important;
}

.yellow-text.text-darken-2 {
  color: #fbc02d !important;
}

.yellow.darken-3 {
  background-color: #f9a825 !important;
}

.yellow-text.text-darken-3 {
  color: #f9a825 !important;
}

.yellow.darken-4 {
  background-color: #f57f17 !important;
}

.yellow-text.text-darken-4 {
  color: #f57f17 !important;
}

.yellow.accent-1 {
  background-color: #ffff8d !important;
}

.yellow-text.text-accent-1 {
  color: #ffff8d !important;
}

.yellow.accent-2 {
  background-color: #ffff00 !important;
}

.yellow-text.text-accent-2 {
  color: #ffff00 !important;
}

.yellow.accent-3 {
  background-color: #ffea00 !important;
}

.yellow-text.text-accent-3 {
  color: #ffea00 !important;
}

.yellow.accent-4 {
  background-color: #ffd600 !important;
}

.yellow-text.text-accent-4 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}

.amber-text {
  color: #ffc107 !important;
}

.amber.lighten-5 {
  background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5 {
  color: #fff8e1 !important;
}

.amber.lighten-4 {
  background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4 {
  color: #ffecb3 !important;
}

.amber.lighten-3 {
  background-color: #ffe082 !important;
}

.amber-text.text-lighten-3 {
  color: #ffe082 !important;
}

.amber.lighten-2 {
  background-color: #ffd54f !important;
}

.amber-text.text-lighten-2 {
  color: #ffd54f !important;
}

.amber.lighten-1 {
  background-color: #ffca28 !important;
}

.amber-text.text-lighten-1 {
  color: #ffca28 !important;
}

.amber.darken-1 {
  background-color: #ffb300 !important;
}

.amber-text.text-darken-1 {
  color: #ffb300 !important;
}

.amber.darken-2 {
  background-color: #ffa000 !important;
}

.amber-text.text-darken-2 {
  color: #ffa000 !important;
}

.amber.darken-3 {
  background-color: #ff8f00 !important;
}

.amber-text.text-darken-3 {
  color: #ff8f00 !important;
}

.amber.darken-4 {
  background-color: #ff6f00 !important;
}

.amber-text.text-darken-4 {
  color: #ff6f00 !important;
}

.amber.accent-1 {
  background-color: #ffe57f !important;
}

.amber-text.text-accent-1 {
  color: #ffe57f !important;
}

.amber.accent-2 {
  background-color: #ffd740 !important;
}

.amber-text.text-accent-2 {
  color: #ffd740 !important;
}

.amber.accent-3 {
  background-color: #ffc400 !important;
}

.amber-text.text-accent-3 {
  color: #ffc400 !important;
}

.amber.accent-4 {
  background-color: #ffab00 !important;
}

.amber-text.text-accent-4 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.orange.lighten-5 {
  background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5 {
  color: #fff3e0 !important;
}

.orange.lighten-4 {
  background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important;
}

.orange.lighten-3 {
  background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3 {
  color: #ffcc80 !important;
}

.orange.lighten-2 {
  background-color: #ffb74d !important;
}

.orange-text.text-lighten-2 {
  color: #ffb74d !important;
}

.orange.lighten-1 {
  background-color: #ffa726 !important;
}

.orange-text.text-lighten-1 {
  color: #ffa726 !important;
}

.orange.darken-1 {
  background-color: #fb8c00 !important;
}

.orange-text.text-darken-1 {
  color: #fb8c00 !important;
}

.orange.darken-2 {
  background-color: #f57c00 !important;
}

.orange-text.text-darken-2 {
  color: #f57c00 !important;
}

.orange.darken-3 {
  background-color: #ef6c00 !important;
}

.orange-text.text-darken-3 {
  color: #ef6c00 !important;
}

.orange.darken-4 {
  background-color: #e65100 !important;
}

.orange-text.text-darken-4 {
  color: #e65100 !important;
}

.orange.accent-1 {
  background-color: #ffd180 !important;
}

.orange-text.text-accent-1 {
  color: #ffd180 !important;
}

.orange.accent-2 {
  background-color: #ffab40 !important;
}

.orange-text.text-accent-2 {
  color: #ffab40 !important;
}

.orange.accent-3 {
  background-color: #ff9100 !important;
}

.orange-text.text-accent-3 {
  color: #ff9100 !important;
}

.orange.accent-4 {
  background-color: #ff6d00 !important;
}

.orange-text.text-accent-4 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important;
}

.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important;
}

.deep-orange.lighten-3 {
  background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important;
}

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important;
}

.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important;
}

.deep-orange.darken-1 {
  background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1 {
  color: #f4511e !important;
}

.deep-orange.darken-2 {
  background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2 {
  color: #e64a19 !important;
}

.deep-orange.darken-3 {
  background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3 {
  color: #d84315 !important;
}

.deep-orange.darken-4 {
  background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4 {
  color: #bf360c !important;
}

.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important;
}

.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important;
}

.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important;
}

.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}

.brown-text {
  color: #795548 !important;
}

.brown.lighten-5 {
  background-color: #efebe9 !important;
}

.brown-text.text-lighten-5 {
  color: #efebe9 !important;
}

.brown.lighten-4 {
  background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important;
}

.brown.lighten-3 {
  background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important;
}

.brown.lighten-2 {
  background-color: #a1887f !important;
}

.brown-text.text-lighten-2 {
  color: #a1887f !important;
}

.brown.lighten-1 {
  background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1 {
  color: #8d6e63 !important;
}

.brown.darken-1 {
  background-color: #6d4c41 !important;
}

.brown-text.text-darken-1 {
  color: #6d4c41 !important;
}

.brown.darken-2 {
  background-color: #5d4037 !important;
}

.brown-text.text-darken-2 {
  color: #5d4037 !important;
}

.brown.darken-3 {
  background-color: #4e342e !important;
}

.brown-text.text-darken-3 {
  color: #4e342e !important;
}

.brown.darken-4 {
  background-color: #3e2723 !important;
}

.brown-text.text-darken-4 {
  color: #3e2723 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}

.blue-grey-text {
  color: #607d8b !important;
}

.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important;
}

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important;
}

.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important;
}

.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important;
}

.blue-grey.lighten-1 {
  background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1 {
  color: #78909c !important;
}

.blue-grey.darken-1 {
  background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1 {
  color: #546e7a !important;
}

.blue-grey.darken-2 {
  background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2 {
  color: #455a64 !important;
}

.blue-grey.darken-3 {
  background-color: #37474f !important;
}

.blue-grey-text.text-darken-3 {
  color: #37474f !important;
}

.blue-grey.darken-4 {
  background-color: #263238 !important;
}

.blue-grey-text.text-darken-4 {
  color: #263238 !important;
}

.grey {
  background-color: #9e9e9e !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.grey.lighten-5 {
  background-color: #fafafa !important;
}

.grey-text.text-lighten-5 {
  color: #fafafa !important;
}

.grey.lighten-4 {
  background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important;
}

.grey.lighten-3 {
  background-color: #eeeeee !important;
}

.grey-text.text-lighten-3 {
  color: #eeeeee !important;
}

.grey.lighten-2 {
  background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important;
}

.grey.lighten-1 {
  background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1 {
  color: #bdbdbd !important;
}

.grey.darken-1 {
  background-color: #757575 !important;
}

.grey-text.text-darken-1 {
  color: #757575 !important;
}

.grey.darken-2 {
  background-color: #616161 !important;
}

.grey-text.text-darken-2 {
  color: #616161 !important;
}

.grey.darken-3 {
  background-color: #424242 !important;
}

.grey-text.text-darken-3 {
  color: #424242 !important;
}

.grey.darken-4 {
  background-color: #212121 !important;
}

.grey-text.text-darken-4 {
  color: #212121 !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.white {
  background-color: #ffffff !important;
}

.white-text {
  color: #ffffff !important;
}

.transparent {
  background-color: transparent !important;
}

.transparent-text {
  color: transparent !important;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.center,
.center-align {
  text-align: center;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.col .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section.no-pad {
  padding: 0;
}

.section.no-pad-bot {
  padding-bottom: 0;
}

.section.no-pad-top {
  padding-top: 0;
}

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.row .col {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
}

.row .col[class*='push-'],
.row .col[class*='pull-'] {
  position: relative;
}

.row .col.s1 {
  width: 8.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s2 {
  width: 16.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s4 {
  width: 33.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5 {
  width: 41.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s7 {
  width: 58.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s8 {
  width: 66.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s10 {
  width: 83.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s11 {
  width: 91.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.offset-s1 {
  margin-left: 8.3333333333%;
}

.row .col.pull-s1 {
  right: 8.3333333333%;
}

.row .col.push-s1 {
  left: 8.3333333333%;
}

.row .col.offset-s2 {
  margin-left: 16.6666666667%;
}

.row .col.pull-s2 {
  right: 16.6666666667%;
}

.row .col.push-s2 {
  left: 16.6666666667%;
}

.row .col.offset-s3 {
  margin-left: 25%;
}

.row .col.pull-s3 {
  right: 25%;
}

.row .col.push-s3 {
  left: 25%;
}

.row .col.offset-s4 {
  margin-left: 33.3333333333%;
}

.row .col.pull-s4 {
  right: 33.3333333333%;
}

.row .col.push-s4 {
  left: 33.3333333333%;
}

.row .col.offset-s5 {
  margin-left: 41.6666666667%;
}

.row .col.pull-s5 {
  right: 41.6666666667%;
}

.row .col.push-s5 {
  left: 41.6666666667%;
}

.row .col.offset-s6 {
  margin-left: 50%;
}

.row .col.pull-s6 {
  right: 50%;
}

.row .col.push-s6 {
  left: 50%;
}

.row .col.offset-s7 {
  margin-left: 58.3333333333%;
}

.row .col.pull-s7 {
  right: 58.3333333333%;
}

.row .col.push-s7 {
  left: 58.3333333333%;
}

.row .col.offset-s8 {
  margin-left: 66.6666666667%;
}

.row .col.pull-s8 {
  right: 66.6666666667%;
}

.row .col.push-s8 {
  left: 66.6666666667%;
}

.row .col.offset-s9 {
  margin-left: 75%;
}

.row .col.pull-s9 {
  right: 75%;
}

.row .col.push-s9 {
  left: 75%;
}

.row .col.offset-s10 {
  margin-left: 83.3333333333%;
}

.row .col.pull-s10 {
  right: 83.3333333333%;
}

.row .col.push-s10 {
  left: 83.3333333333%;
}

.row .col.offset-s11 {
  margin-left: 91.6666666667%;
}

.row .col.pull-s11 {
  right: 91.6666666667%;
}

.row .col.push-s11 {
  left: 91.6666666667%;
}

.row .col.offset-s12 {
  margin-left: 100%;
}

.row .col.pull-s12 {
  right: 100%;
}

.row .col.push-s12 {
  left: 100%;
}

@media only screen and (min-width: 601px) {
  .row .col.m1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-m1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-m1 {
    right: 8.3333333333%;
  }
  .row .col.push-m1 {
    left: 8.3333333333%;
  }
  .row .col.offset-m2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-m2 {
    right: 16.6666666667%;
  }
  .row .col.push-m2 {
    left: 16.6666666667%;
  }
  .row .col.offset-m3 {
    margin-left: 25%;
  }
  .row .col.pull-m3 {
    right: 25%;
  }
  .row .col.push-m3 {
    left: 25%;
  }
  .row .col.offset-m4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-m4 {
    right: 33.3333333333%;
  }
  .row .col.push-m4 {
    left: 33.3333333333%;
  }
  .row .col.offset-m5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-m5 {
    right: 41.6666666667%;
  }
  .row .col.push-m5 {
    left: 41.6666666667%;
  }
  .row .col.offset-m6 {
    margin-left: 50%;
  }
  .row .col.pull-m6 {
    right: 50%;
  }
  .row .col.push-m6 {
    left: 50%;
  }
  .row .col.offset-m7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-m7 {
    right: 58.3333333333%;
  }
  .row .col.push-m7 {
    left: 58.3333333333%;
  }
  .row .col.offset-m8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-m8 {
    right: 66.6666666667%;
  }
  .row .col.push-m8 {
    left: 66.6666666667%;
  }
  .row .col.offset-m9 {
    margin-left: 75%;
  }
  .row .col.pull-m9 {
    right: 75%;
  }
  .row .col.push-m9 {
    left: 75%;
  }
  .row .col.offset-m10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-m10 {
    right: 83.3333333333%;
  }
  .row .col.push-m10 {
    left: 83.3333333333%;
  }
  .row .col.offset-m11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-m11 {
    right: 91.6666666667%;
  }
  .row .col.push-m11 {
    left: 91.6666666667%;
  }
  .row .col.offset-m12 {
    margin-left: 100%;
  }
  .row .col.pull-m12 {
    right: 100%;
  }
  .row .col.push-m12 {
    left: 100%;
  }
}

@media only screen and (min-width: 993px) {
  .row .col.l1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-l1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-l1 {
    right: 8.3333333333%;
  }
  .row .col.push-l1 {
    left: 8.3333333333%;
  }
  .row .col.offset-l2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-l2 {
    right: 16.6666666667%;
  }
  .row .col.push-l2 {
    left: 16.6666666667%;
  }
  .row .col.offset-l3 {
    margin-left: 25%;
  }
  .row .col.pull-l3 {
    right: 25%;
  }
  .row .col.push-l3 {
    left: 25%;
  }
  .row .col.offset-l4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-l4 {
    right: 33.3333333333%;
  }
  .row .col.push-l4 {
    left: 33.3333333333%;
  }
  .row .col.offset-l5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-l5 {
    right: 41.6666666667%;
  }
  .row .col.push-l5 {
    left: 41.6666666667%;
  }
  .row .col.offset-l6 {
    margin-left: 50%;
  }
  .row .col.pull-l6 {
    right: 50%;
  }
  .row .col.push-l6 {
    left: 50%;
  }
  .row .col.offset-l7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-l7 {
    right: 58.3333333333%;
  }
  .row .col.push-l7 {
    left: 58.3333333333%;
  }
  .row .col.offset-l8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-l8 {
    right: 66.6666666667%;
  }
  .row .col.push-l8 {
    left: 66.6666666667%;
  }
  .row .col.offset-l9 {
    margin-left: 75%;
  }
  .row .col.pull-l9 {
    right: 75%;
  }
  .row .col.push-l9 {
    left: 75%;
  }
  .row .col.offset-l10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-l10 {
    right: 83.3333333333%;
  }
  .row .col.push-l10 {
    left: 83.3333333333%;
  }
  .row .col.offset-l11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-l11 {
    right: 91.6666666667%;
  }
  .row .col.push-l11 {
    left: 91.6666666667%;
  }
  .row .col.offset-l12 {
    margin-left: 100%;
  }
  .row .col.pull-l12 {
    right: 100%;
  }
  .row .col.push-l12 {
    left: 100%;
  }
}

@media only screen and (min-width: 1201px) {
  .row .col.xl1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-xl1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-xl1 {
    right: 8.3333333333%;
  }
  .row .col.push-xl1 {
    left: 8.3333333333%;
  }
  .row .col.offset-xl2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-xl2 {
    right: 16.6666666667%;
  }
  .row .col.push-xl2 {
    left: 16.6666666667%;
  }
  .row .col.offset-xl3 {
    margin-left: 25%;
  }
  .row .col.pull-xl3 {
    right: 25%;
  }
  .row .col.push-xl3 {
    left: 25%;
  }
  .row .col.offset-xl4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-xl4 {
    right: 33.3333333333%;
  }
  .row .col.push-xl4 {
    left: 33.3333333333%;
  }
  .row .col.offset-xl5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-xl5 {
    right: 41.6666666667%;
  }
  .row .col.push-xl5 {
    left: 41.6666666667%;
  }
  .row .col.offset-xl6 {
    margin-left: 50%;
  }
  .row .col.pull-xl6 {
    right: 50%;
  }
  .row .col.push-xl6 {
    left: 50%;
  }
  .row .col.offset-xl7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-xl7 {
    right: 58.3333333333%;
  }
  .row .col.push-xl7 {
    left: 58.3333333333%;
  }
  .row .col.offset-xl8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-xl8 {
    right: 66.6666666667%;
  }
  .row .col.push-xl8 {
    left: 66.6666666667%;
  }
  .row .col.offset-xl9 {
    margin-left: 75%;
  }
  .row .col.pull-xl9 {
    right: 75%;
  }
  .row .col.push-xl9 {
    left: 75%;
  }
  .row .col.offset-xl10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-xl10 {
    right: 83.3333333333%;
  }
  .row .col.push-xl10 {
    left: 83.3333333333%;
  }
  .row .col.offset-xl11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-xl11 {
    right: 91.6666666667%;
  }
  .row .col.push-xl11 {
    left: 91.6666666667%;
  }
  .row .col.offset-xl12 {
    margin-left: 100%;
  }
  .row .col.pull-xl12 {
    right: 100%;
  }
  .row .col.push-xl12 {
    left: 100%;
  }
}

.user-view {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.user-view > a {
  height: auto;
  padding: 0;
}

.user-view > a:hover {
  background-color: transparent;
}

.user-view .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.user-view .circle,
.user-view .name,
.user-view .email {
  display: block;
}

.user-view .circle {
  height: 64px;
  width: 64px;
}

.user-view .name,
.user-view .email {
  font-size: 14px;
  line-height: 24px;
}

.user-view .name {
  margin-top: 16px;
  font-weight: 500;
}

.user-view .email {
  padding-bottom: 16px;
  font-weight: 400;
}

.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}

.drag-target.right-aligned {
  right: 0;
}

.sidenav.sidenav-fixed {
  left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  position: fixed;
}

.sidenav.sidenav-fixed.right-aligned {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 992px) {
  .sidenav.sidenav-fixed {
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
  }
  .sidenav.sidenav-fixed.right-aligned {
    -webkit-transform: translateX(105%);
    transform: translateX(105%);
  }
  .sidenav > a {
    padding: 0 16px;
  }
  .user-view {
    padding: 16px 16px 0;
  }
}

.inputCenter {
  text-align: center !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px 10px;
}

::-webkit-scrollbar-thumb {
  background: #2c9475;
  border-radius: 15px 2px 15px 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #155845;
}

.treeFont {
  font-size: 12px !important;
  color: #444 !important;
}

.treeFather {
  font-size: 1.4em !important;
}

.treeChild {
  font-size: 1em;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100%;
}

*:focus,
button,
button:focus,
button:active {
  outline: 0;
  outline-width: 0;
  outline: none;
  outline-style: none;
}

@media (max-width: 599px) {
  .pull-right-lg {
    float: right !important;
  }
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ng-scrollbar-y {
  --scrollbar-color: transparent;
  --scrollbar-container-color: transparent;
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
  --scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.3);
  --scrollbar-border-radius: 4px;
  --scrollbar-size: 6px;
  --scrollbar-padding: 2px;
  --scroll-view-margin: 0;
  --scroll-view-color: transparent;
}

@media (max-width: 800px) {
  .responsive_table {
    overflow-x: auto !important;
  }
    .mat-table {
    min-width: 800px;
  } 
}

.pointer {
  cursor: pointer;
}

button {
  text-transform: uppercase;
}

.table-50 {
  width: 50%;
}

.table-100 {
  width: 100%;
}

.color-icon-edit {
  color: #3f51b5;
}

.color-icon-activo {
  color: #00c853;
}

.color-icon-delete {
  color: #d50000;
}



.font-weight-bold {
  font-weight: bold;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table,
th,
td {
  border: none;
}

.table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

.table.striped tr {
  border-bottom: none;
}

.table.striped > tbody > tr:nth-child(odd) {
  background-color: rgba(242, 242, 242, 0.5);
}

.table.striped > tbody > tr > td {
  border-radius: 0;
}

.table.highlight > tbody > tr {
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}

.table.highlight > tbody > tr:hover {
  background-color: rgba(242, 242, 242, 0.5);
}

.table.centered thead tr th,
.table.centered tbody tr td {
  text-align: center;
}

.table * tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table * td,
.table * th {
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}

.table * th {
  text-transform: uppercase;
}

@media only screen and (max-width: 992px) {
  .table.responsive-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;
    /* sort out borders */
  }
  .table.responsive-table td:empty:before {
    content: '\00a0';
  }
  .table.responsive-table th,
  .table.responsive-table td {
    margin: 0;
    vertical-align: top;
  }
  .table.responsive-table th {
    text-align: left;
  }
  table.responsive-table thead {
    display: block;
    float: left;
  }
  .table.responsive-table thead tr {
    display: block;
    padding: 0 10px 0 0;
  }
  .table.responsive-table thead tr th::before {
    content: '\00a0';
  }
  .table.responsive-table tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table.responsive-table tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .table.responsive-table th {
    display: block;
    text-align: right;
  }
  .table.responsive-table td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }
  .table.responsive-table tr {
    border-bottom: none;
    padding: 0 10px;
  }
  .table.responsive-table thead {
    border: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
}

img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .hide-on-small-only,
  .hide-on-small-and-down {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important;
  }
}

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .hide-on-extra-large-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .show-on-extra-large {
    display: block !important;
  }
}

@media only screen and (min-width: 993px) {
  .show-on-large {
    display: block !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important;
  }
}

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center;
  }
}

.mat-editor .mat-form-field-wrapper,
.mat-editor .mat-form-field-infix {
  padding-top: 0;
  padding-bottom: 0;
}

.mat-editor .mat-form-field-flex {
  align-items: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center,
.center-align {
  text-align: center;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

@keyframes ladda-spinner-line-fade {
  0%,
  100% {
    opacity: 0.22;
  }
  1% {
    opacity: 1;
  }
}

.ladda-button {
  position: relative;
}

.ladda-button .ladda-spinner {
  position: absolute;
  z-index: 2;
  display: inline-block;
  width: 32px;
  top: 50%;
  margin-top: 0;
  opacity: 0;
  pointer-events: none;
}

.ladda-button .ladda-label {
  position: relative;
  z-index: 3;
}

.ladda-button .ladda-progress {
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  transition: 0.1s linear all !important;
}

.ladda-button[data-loading] .ladda-progress {
  display: block;
}

.ladda-button,
.ladda-button .ladda-spinner,
.ladda-button .ladda-label {
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
}

.ladda-button[data-style='zoom-in'],
.ladda-button[data-style='zoom-in'] .ladda-spinner,
.ladda-button[data-style='zoom-in'] .ladda-label,
.ladda-button[data-style='zoom-out'],
.ladda-button[data-style='zoom-out'] .ladda-spinner,
.ladda-button[data-style='zoom-out'] .ladda-label {
  transition: 0.3s ease all !important;
}

.ladda-button[data-style='expand-right'] .ladda-spinner {
  right: -6px;
}

.ladda-button[data-style='expand-right'][data-size='s'] .ladda-spinner,
.ladda-button[data-style='expand-right'][data-size='xs'] .ladda-spinner {
  right: -12px;
}

.ladda-button[data-style='expand-right'][data-loading] {
  padding-right: 56px;
}

.ladda-button[data-style='expand-right'][data-loading] .ladda-spinner {
  opacity: 1;
}

.ladda-button[data-style='expand-right'][data-loading][data-size='s'],
.ladda-button[data-style='expand-right'][data-loading][data-size='xs'] {
  padding-right: 40px;
}

.ladda-button[data-style='expand-left'] .ladda-spinner {
  left: 26px;
}

.ladda-button[data-style='expand-left'][data-size='s'] .ladda-spinner,
.ladda-button[data-style='expand-left'][data-size='xs'] .ladda-spinner {
  left: 4px;
}

.ladda-button[data-style='expand-left'][data-loading] {
  padding-left: 56px;
}

.ladda-button[data-style='expand-left'][data-loading] .ladda-spinner {
  opacity: 1;
}

.ladda-button[data-style='expand-left'][data-loading][data-size='s'],
.ladda-button[data-style='expand-left'][data-loading][data-size='xs'] {
  padding-left: 40px;
}

.ladda-button[data-style='expand-up'] {
  overflow: hidden;
}

.ladda-button[data-style='expand-up'] .ladda-spinner {
  top: -32px;
  left: 50%;
  margin-left: 0;
}

.ladda-button[data-style='expand-up'][data-loading] {
  padding-top: 54px;
}

.ladda-button[data-style='expand-up'][data-loading] .ladda-spinner {
  opacity: 1;
  top: 26px;
  margin-top: 0;
}

.ladda-button[data-style='expand-up'][data-loading][data-size='s'],
.ladda-button[data-style='expand-up'][data-loading][data-size='xs'] {
  padding-top: 32px;
}

.ladda-button[data-style='expand-up'][data-loading][data-size='s']
  .ladda-spinner,
.ladda-button[data-style='expand-up'][data-loading][data-size='xs']
  .ladda-spinner {
  top: 4px;
}

.ladda-button[data-style='expand-down'] {
  overflow: hidden;
}

.ladda-button[data-style='expand-down'] .ladda-spinner {
  top: 62px;
  left: 50%;
  margin-left: 0;
}

.ladda-button[data-style='expand-down'][data-size='s'] .ladda-spinner,
.ladda-button[data-style='expand-down'][data-size='xs'] .ladda-spinner {
  top: 40px;
}

.ladda-button[data-style='expand-down'][data-loading] {
  padding-bottom: 54px;
}

.ladda-button[data-style='expand-down'][data-loading] .ladda-spinner {
  opacity: 1;
}

.ladda-button[data-style='expand-down'][data-loading][data-size='s'],
.ladda-button[data-style='expand-down'][data-loading][data-size='xs'] {
  padding-bottom: 32px;
}

.ladda-button[data-style='slide-left'] {
  overflow: hidden;
}

.ladda-button[data-style='slide-left'] .ladda-label {
  position: relative;
}

.ladda-button[data-style='slide-left'] .ladda-spinner {
  left: 100%;
  margin-left: 0;
}

.ladda-button[data-style='slide-left'][data-loading] .ladda-label {
  opacity: 0;
  left: -100%;
}

.ladda-button[data-style='slide-left'][data-loading] .ladda-spinner {
  opacity: 1;
  left: 50%;
}

.ladda-button[data-style='slide-right'] {
  overflow: hidden;
}

.ladda-button[data-style='slide-right'] .ladda-label {
  position: relative;
}

.ladda-button[data-style='slide-right'] .ladda-spinner {
  right: 100%;
  margin-left: 0;
  left: 16px;
}

[dir='rtl'] .ladda-button[data-style='slide-right'] .ladda-spinner {
  right: auto;
}

.ladda-button[data-style='slide-right'][data-loading] .ladda-label {
  opacity: 0;
  left: 100%;
}

.ladda-button[data-style='slide-right'][data-loading] .ladda-spinner {
  opacity: 1;
  left: 50%;
}

.ladda-button[data-style='slide-up'] {
  overflow: hidden;
}

.ladda-button[data-style='slide-up'] .ladda-label {
  position: relative;
}

.ladda-button[data-style='slide-up'] .ladda-spinner {
  left: 50%;
  margin-left: 0;
  margin-top: 1em;
}

.ladda-button[data-style='slide-up'][data-loading] .ladda-label {
  opacity: 0;
  top: -1em;
}

.ladda-button[data-style='slide-up'][data-loading] .ladda-spinner {
  opacity: 1;
  margin-top: 0;
}

.ladda-button[data-style='slide-down'] {
  overflow: hidden;
}

.ladda-button[data-style='slide-down'] .ladda-label {
  position: relative;
}

.ladda-button[data-style='slide-down'] .ladda-spinner {
  left: 50%;
  margin-left: 0;
  margin-top: -2em;
}

.ladda-button[data-style='slide-down'][data-loading] .ladda-label {
  opacity: 0;
  top: 1em;
}

.ladda-button[data-style='slide-down'][data-loading] .ladda-spinner {
  opacity: 1;
  margin-top: 0;
}

.ladda-button[data-style='zoom-out'] {
  overflow: hidden;
}

.ladda-button[data-style='zoom-out'] .ladda-spinner {
  left: 50%;
  margin-left: 32px;
  transform: scale(2.5);
}

.ladda-button[data-style='zoom-out'] .ladda-label {
  position: relative;
  display: inline-block;
}

.ladda-button[data-style='zoom-out'][data-loading] .ladda-label {
  opacity: 0;
  transform: scale(0.5);
}

.ladda-button[data-style='zoom-out'][data-loading] .ladda-spinner {
  opacity: 1;
  margin-left: 0;
  transform: none;
}

.ladda-button[data-style='zoom-in'] {
  overflow: hidden;
}

.ladda-button[data-style='zoom-in'] .ladda-spinner {
  left: 50%;
  margin-left: -16px;
  transform: scale(0.2);
}

.ladda-button[data-style='zoom-in'] .ladda-label {
  position: relative;
  display: inline-block;
}

.ladda-button[data-style='zoom-in'][data-loading] .ladda-label {
  opacity: 0;
  transform: scale(2.2);
}

.ladda-button[data-style='zoom-in'][data-loading] .ladda-spinner {
  opacity: 1;
  margin-left: 0;
  transform: none;
}

.ladda-button[data-style='contract'] {
  overflow: hidden;
  width: 100px;
}

.ladda-button[data-style='contract'] .ladda-spinner {
  left: 50%;
  margin-left: 0;
}

.ladda-button[data-style='contract'][data-loading] {
  border-radius: 50%;
  width: 52px;
}

.ladda-button[data-style='contract'][data-loading] .ladda-label {
  opacity: 0;
}

.ladda-button[data-style='contract'][data-loading] .ladda-spinner {
  opacity: 1;
}

.ladda-button[data-style='contract-overlay'] {
  overflow: hidden;
  width: 100px;
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0);
}

.ladda-button[data-style='contract-overlay'] .ladda-spinner {
  left: 50%;
  margin-left: 0;
}

.ladda-button[data-style='contract-overlay'][data-loading] {
  border-radius: 50%;
  width: 52px;
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.8);
}

.ladda-button[data-style='contract-overlay'][data-loading] .ladda-label {
  opacity: 0;
}

.ladda-button[data-style='contract-overlay'][data-loading] .ladda-spinner {
  opacity: 1;
}

[dir='rtl'] .ladda-spinner > div {
  left: 25% !important;
}

.ladda-button {
  /* background: #666; */
  border: 0;
  padding: 14px 18px;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ladda-button:hover {
  border-color: rgba(0, 0, 0, 0.07);
  /* background-color: #888 */
}

.ladda-button[data-color='green'] {
  background: #2aca76;
}

.ladda-button[data-color='green']:hover {
  background-color: #38d683;
}

.ladda-button[data-color='blue'] {
  background: #53b5e6;
}

.ladda-button[data-color='blue']:hover {
  background-color: #69bfe9;
}

.ladda-button[data-color='red'] {
  background: #ea8557;
}

.ladda-button[data-color='red']:hover {
  background-color: #ed956e;
}

.ladda-button[data-color='purple'] {
  background: #9973c2;
}

.ladda-button[data-color='purple']:hover {
  background-color: #a685ca;
}

.ladda-button[data-color='mint'] {
  background: #16a085;
}

.ladda-button[data-color='mint']:hover {
  background-color: #19b698;
}

/* .ladda-button[disabled], .ladda-button[data-loading] {
  border-color: rgba(0, 0, 0, 0.07)
} */

.ladda-button[disabled],
.ladda-button[disabled]:hover,
.ladda-button[data-loading],
.ladda-button[data-loading]:hover {
  cursor: default;
  /* background-color: #999 */
}

.ladda-button[data-size='xs'] {
  padding: 4px 8px;
}

.ladda-button[data-size='xs'] .ladda-label {
  font-size: 0.7em;
}

.ladda-button[data-size='s'] {
  padding: 6px 10px;
}

.ladda-button[data-size='s'] .ladda-label {
  font-size: 0.9em;
}

.ladda-button[data-size='l'] .ladda-label {
  font-size: 1.2em;
}

.ladda-button[data-size='xl'] .ladda-label {
  font-size: 1.5em;
}

.color-estado-activo {
  color: #00c853;
}

.color-estado-inactivo {
  color: #d50000;
}

/* @font-face {
  font-family: Pacciolo;
  src: url('./assets/fonts/CaviarDreams.ttf'),
} */

/*
 * See: https://fonts.google.com/license/googlerestricted
 */

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 100;
  src: local('Product Sans Thin Italic'), local('ProductSans-ThinItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxifypQkot1TnFhsFMOfGShVEu_vWEpkr1ap.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 100;
  src: local('Product Sans Thin Italic'), local('ProductSans-ThinItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxifypQkot1TnFhsFMOfGShVEu_vWE1kr1ap.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 100;
  src: local('Product Sans Thin Italic'), local('ProductSans-ThinItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxifypQkot1TnFhsFMOfGShVEu_vWEBkr1ap.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 100;
  src: local('Product Sans Thin Italic'), local('ProductSans-ThinItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxifypQkot1TnFhsFMOfGShVEu_vWE5krw.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Product Sans Light Italic'), local('ProductSans-LightItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8nSllHimuQpw.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Product Sans Light Italic'), local('ProductSans-LightItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8nSllAimuQpw.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Product Sans Light Italic'), local('ProductSans-LightItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8nSllNimuQpw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Product Sans Light Italic'), local('ProductSans-LightItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8nSllDims.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Product Sans Italic'), local('ProductSans-Italic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShVEueIaEx8qw.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Product Sans Italic'), local('ProductSans-Italic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShVEuePaEx8qw.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Product Sans Italic'), local('ProductSans-Italic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShVEueCaEx8qw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Product Sans Italic'), local('ProductSans-Italic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShVEueMaEw.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Product Sans Medium Italic'), local('ProductSans-MediumItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu9_S1lHimuQpw.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Product Sans Medium Italic'), local('ProductSans-MediumItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu9_S1lAimuQpw.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Product Sans Medium Italic'), local('ProductSans-MediumItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu9_S1lNimuQpw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Product Sans Medium Italic'), local('ProductSans-MediumItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu9_S1lDims.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Product Sans Bold Italic'), local('ProductSans-BoldItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu83TVlHimuQpw.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Product Sans Bold Italic'), local('ProductSans-BoldItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu83TVlAimuQpw.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Product Sans Bold Italic'), local('ProductSans-BoldItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu83TVlNimuQpw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Product Sans Bold Italic'), local('ProductSans-BoldItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu83TVlDims.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 900;
  src: local('Product Sans Black Italic'), local('ProductSans-BlackItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8PT1lHimuQpw.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 900;
  src: local('Product Sans Black Italic'), local('ProductSans-BlackItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8PT1lAimuQpw.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 900;
  src: local('Product Sans Black Italic'), local('ProductSans-BlackItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8PT1lNimuQpw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 900;
  src: local('Product Sans Black Italic'), local('ProductSans-BlackItalic'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxieypQkot1TnFhsFMOfGShVEu8PT1lDims.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Product Sans Thin'), local('ProductSans-Thin'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShddOeIaEx8qw.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Product Sans Thin'), local('ProductSans-Thin'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShddOePaEx8qw.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Product Sans Thin'), local('ProductSans-Thin'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShddOeCaEx8qw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 100;
  src: local('Product Sans Thin'), local('ProductSans-Thin'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxidypQkot1TnFhsFMOfGShddOeMaEw.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdvPWbS2lBkm8.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdvPWbTGlBkm8.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdvPWbQWlBkm8.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdvPWbT2lB.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxiDypQkot1TnFhsFMOfGShVE9eOcEg.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxiDypQkot1TnFhsFMOfGShVFNeOcEg.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxiDypQkot1TnFhsFMOfGShVGdeOcEg.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxiDypQkot1TnFhsFMOfGShVF9eO.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Product Sans Medium'), local('ProductSans-Medium'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShd5PSbS2lBkm8.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Product Sans Medium'), local('ProductSans-Medium'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShd5PSbTGlBkm8.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Product Sans Medium'), local('ProductSans-Medium'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShd5PSbQWlBkm8.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Product Sans Medium'), local('ProductSans-Medium'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShd5PSbT2lB.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdrPKbS2lBkm8.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdrPKbTGlBkm8.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdrPKbQWlBkm8.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdrPKbT2lB.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Product Sans Black'), local('ProductSans-Black'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdlPCbS2lBkm8.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Product Sans Black'), local('ProductSans-Black'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdlPCbTGlBkm8.woff2)
      format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Product Sans Black'), local('ProductSans-Black'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdlPCbQWlBkm8.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Product Sans Black'), local('ProductSans-Black'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdlPCbT2lB.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* * {
  font-family: 'Product Sans';
} */

body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
button,
ul,
div,
td,
th {
  font-family: 'Product Sans' !important;
}

tr:hover {
  background-color: #f5f5f5;
}

th:hover {
  background-color: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

th {
  font-weight: 700 !important;
  text-align: center !important;
  color: #595959 !important;
}

td {
  text-align: center !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-45 {
  margin-top: 45px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-2-per {
  margin-top: 2%;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-17 {
  margin-bottom: 17px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-left-45 {
  margin-left: 45px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-55 {
  margin-left: 55px;
}

.margin-left-70 {
  margin-left: 70px;
}

.nom-person-margin-right {
  margin: 20px 135px 0px 0px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-right-97 {
  margin-right: 97px;
}

.padding-top-20 {
  padding-top: 20px;
}

.max-height-250 {
  max-height: 250px;
}

.bg-btn-green {
  color: #ffffff;
  background-color: #0c8361;
}

.bg-btn-blue {
  color: #ffffff;
  background-color: #009fe3;
}

.color-black-text {
  color: #212121 !important;
}

.color-red-text {
  color: #cc0000;
}

.color-blue-text {
  color: #009fe3;
}

.color-green-text {
  color: #0c8361;
}

@media only screen and (min-width: 376px) and (max-width: 414px) {
  .margin-top-33- {
    margin-top: -33px;
  }
  .button-add-direccion {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .margin-bottom-20 {
    margin-bottom: 25px;
  }
  .margin-top-33- {
    margin-top: -33px;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .button-add-direccion {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 415px) {
  .margin-top-33- {
    margin-top: 0px;
  }
}

.font-size-10 {
  font-size: 10px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-50 {
  font-size: 50px;
}

.text-align-start {
  text-align: start;
}

.text-align-center {
  text-align: center;
}

.text-center .mat-sort-header-container {
  justify-content: center;
} 

.text-align-end {
  text-align: end;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}

.line-height-1 {
  line-height: 1;
}

.line-height-05 {
  line-height: 0.5;
}

.consecutivo {
  cursor: pointer;
}

.consecutivo:hover {
  text-decoration: underline;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.puntos {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* .sizeIcon {
  font-size: 50px;
}

@media (max-width: 1400px) {
  .media {
      height: 40px;
  }
}

.image-upload>form input {
  display: none;
}

.image-upload {
  color: #0c8361;
  width: 30px;
  cursor: pointer;
} */

.p-msj {
  font-size: 20px;
  font-weight: bold;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
