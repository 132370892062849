@import "~@angular/material/theming";
@include mat-core();

$md-colorprincipal: (
  50: #e2f0ec,
  100: #b6dad0,
  200: #86c1b0,
  300: #55a890,
  400: #309679,
  500: #0c8361,
  600: #0a7b59,
  700: #08704f,
  800: #066645,
  900: #035333,
  A100: #85ffc7,
  A200: #52ffaf,
  A400: #1fff98,
  A700: #06ff8c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-colorsecundario: (
  50: #e6f5fb,
  100: #c0e6f6,
  200: #96d5f0,
  300: #6cc4ea,
  400: #4db7e6,
  500: #2daae1,
  600: #28a3dd,
  700: #2299d9,
  800: #1c90d5,
  900: #117fcd,
  A100: #fbfdff,
  A200: #1c90d5,
  A400: #009fe3,
  A700: #7bc4ff,
  A800: #95d0ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the default theme (same as the example above).
$candy-app-primary: mat-palette($md-colorprincipal);
$candy-app-accent: mat-palette($md-colorsecundario, A200, A100, A400);
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent);

// Include the default theme styles.
@include angular-material-theme($candy-app-theme);

// Define an alternate dark theme.
$dark-primary: mat-palette($mat-blue-grey);
$dark-accent: mat-palette($mat-amber, A200, A100, A400);
$dark-warn: mat-palette($mat-deep-orange);
$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.unicorn-dark-theme {
  @include angular-material-theme($dark-theme);
}
